body {
  margin: 0;
  font-family:
    'Montserrat',
    'Space Grotesk',
    'Work Sans',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p > code,
li > code,
dd > code,
td > code {
  background: rgba(0, 255, 0, 0.15);
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: 0.1rem 0.3rem 0.2rem;
  border-radius: 0.2rem;
}

html,
body {
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
